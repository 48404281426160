import { mapGetters, mapActions } from 'vuex';

export const accommodationsService = {
    computed: {
        ...mapGetters([
            'accommodationInspectionSummary', 'accommodationInspectionSummaryLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getAccommodationInspectionSummary'
        ])
    }
}