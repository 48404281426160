import Vue from "vue";
import VueRouter from "vue-router";
import {
  TournamentWrapperPage
} from "@/app/Tournaments";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Overview",
    component: TournamentWrapperPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.environment === "development" ? "/" : "/wedstrijdkalender/",
  routes
});

export default router;
