<template>
  <b-overlay :show="tournamentLoading" rounded="sm">
    <tournament-details-header
      :tournament="tournament"
      id="tournament-details-header"
    />

    <tournament-details-card v-if="isDetailsPage" />

    <tournament-details-frame-card
      :header-text="$t('tournament.timeschedule_and_results')"
      v-if="isResultsPage || isTimeschedulePage"
      :url="resultsUrl"
    />

    <tournament-details-frame-card
      :header-text="$t('common.participants')"
      v-if="isParticipantsPage"
      :url="participantsUrl"
    />
  </b-overlay>
</template>

<script>
import { tournamentsService } from "@/services";
import { registrationSystemsEnum, pageNamesEnum } from "@/shared";
import {
  TournamentDetailsCard,
  TournamentDetailsFrameCard,
  TournamentDetailsHeader,
} from "@/app/Tournaments";

export default {
  mixins: [tournamentsService, registrationSystemsEnum, pageNamesEnum],
  components: {
    TournamentDetailsHeader,
    TournamentDetailsCard,
    TournamentDetailsFrameCard,
  },
  methods: {
    async loadData() {
      await this.getTournament({
        params: {
          id: this.tournamentId,
        },
      });
    },
  },
  computed: {
    isParticipantsPage() {
      return this.$route.query.page === this.pageNames.Participants;
    },
    isDetailsPage() {
      return this.$route.query.id && !this.$route.query.page;
    },
    isResultsPage() {
      return this.$route.query.page === this.pageNames.Results;
    },
    isTimeschedulePage() {
      return this.$route.query.page === this.pageNames.TimeSchedule;
    },
    isSeltecRegistrationSystem() {
      return (
        this.tournament.registrationSystem === this.registrationSystems.Seltec
      );
    },
    isAtletiekNuRegistrationSystem() {
      return (
        this.tournament.registrationSystem ===
        this.registrationSystems.AtleticNU
      );
    },
    tournamentId() {
      return this.$route.query.id;
    },
    resultsUrl() {
      if (this.isSeltecRegistrationSystem) {
        return `https://uitslagen.atletiek.nl/Competitions/external/KNAU_Volta/${this.tournament.id}?lang=nl`;
      }
      console.log("Cannot determine results url");
      return "";
    },
    participantsUrl() {
      if (this.isSeltecRegistrationSystem) {
        return `https://lanet3.nl/external/volta/Entrylist/${this.tournament.id}`;
      }

      console.log("Cannot determine participants url");
      return "";
    },
  },
  mounted() {
    this.loadData();
    document
      .getElementById("tournament-details-header")
      .scrollIntoView({ block: "center", behavior: "smooth" });
  },
};
</script>