<template>
  <b-container>
    <h4 v-if="headerText">{{ headerText }}</h4>
    <iframe
      :src="url"
      id="seltecFrame"
      style="border: 0"
      @load="iframeLoaded"
    /> 
  </b-container>
</template>

<script>
import iFrameResize from "iframe-resizer/js/iframeResizer";

export default {
  props: {
    headerText: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    iframeLoaded() {
      iFrameResize(
        {
          log: false,
          checkOrigin: false,
          sizeHeight: true,
          minHeight: 800,
          heightCalculationMethod: "bodyOffset",
        },
        "#seltecFrame"
      );
    },
  },
};
</script>

<style scoped>
iframe {
  width: 1px;
  min-width: 100%;
}
</style>