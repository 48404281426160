<template>
  <b-container fluid>
    <b-row class="py-2">
      <b-col class="text-center">
        <b-button
          variant="outline-secondary"
          class="mr-4"
          @click="filters.isHistoric = false"
        >
          {{ $t("common.calendar") }}
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="filters.isHistoric = true"
        >
          {{ $t("common.results") }}
        </b-button>
      </b-col>
    </b-row>

    <b-row class="py-2">
      <b-col
        xs="12"
        sm="8"
        offset-sm="2"
        md="6"
        offset-md="3"
        class="text-center"
      >
        <b-input-group>
          <b-form-input
            v-model="quickSearch"
            class="float-right"
            :placeholder="$t('tournaments.search')"
          />
          <template #append>
            <b-button variant="primary" size="sm" @click="fetchTournaments">{{
              $t("tournaments.search")
            }}</b-button>
            <b-button variant="link" size="sm" v-b-toggle.filters>{{
              $t("common.advanced_filters")
            }}</b-button>
          </template>
        </b-input-group>
      </b-col>
    </b-row>

    <b-collapse id="filters" class="pt-4" v-model="showCollapse">
      <b-row>
        <b-col sm="0" md="2"></b-col>
        <b-col md="8">
          <!-- START DATE AFTER -->
          <base-date-picker
            :label="$t('common.start_date_after')"
            :label-cols-sm="labelColsSm"
            v-model="filters.startDateAfter"
          />

          <!-- START DATE AFTER -->
          <base-date-picker
            :label="$t('common.start_date_before')"
            :label-cols-sm="labelColsSm"
            v-model="filters.startDateBefore"
          />

          <!-- INDOOR -->
          <b-form-group
            :label="$t('tournament.outdoor_or_indoor')"
            :label-cols-sm="labelColsSm"
            class="no-delimiter mb-1 pb-1"
          >
            <multiselect
              v-model="filters.isIndoorFilter"
              :options="[
                { id: null, name: $t('common.all') },
                { id: true, name: $t('tournament.indoor') },
                { id: false, name: $t('tournament.outdoor') },
              ]"
              :multiple="false"
              :searchable="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              label="name"
              track-by="id"
              placeholder=""
            />
          </b-form-group>

          <!-- CATEGORIES -->
          <b-form-group
            :label="$t('tournament.category')"
            :label-cols-sm="labelColsSm"
            class="no-delimiter mb-1 pb-1"
          >
            <multiselect
              v-model="filters.categories"
              :options="settings.categories || []"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              label="name"
              track-by="id"
              placeholder=""
            />
          </b-form-group>

          <!-- DISCIPLINES -->
          <b-form-group
            :label="$t('tournament.disciplines')"
            :label-cols-sm="labelColsSm"
            class="no-delimiter mb-1 pb-1"
          >
            <multiselect
              v-model="filters.disciplines"
              :options="settings.disciplines || []"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              label="name"
              track-by="id"
              placeholder=""
            />
          </b-form-group>

          <!-- TOURNAMENT TYPES -->
          <b-form-group
            :label="$t('tournaments.tournament_type')"
            :label-cols-sm="labelColsSm"
            class="no-delimiter mb-1 pb-1"
          >
            <multiselect
              v-model="filters.tournamentTypes"
              :options="settings.tournamentTypes || []"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              label="name"
              track-by="id"
              placeholder=""
            />
          </b-form-group>

          <!-- STATUSES -->
          <b-form-group
            :label="$t('common.status')"
            :label-cols-sm="labelColsSm"
            class="no-delimiter mb-1 pb-1"
          >
            <multiselect
              v-model="filters.statuses"
              :options="statusesList"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              label="name"
              track-by="id"
              placeholder=""
            />
          </b-form-group>

          <!-- ORGANISATIONS -->
          <b-form-group
            :label="$t('tournament.organisation')"
            :label-cols-sm="labelColsSm"
            class="no-delimiter mb-1 pb-1"
          >
            <multiselect
              v-model="filters.organisations"
              :options="settings.organisations || []"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              label="name"
              track-by="id"
              placeholder=""
            />
          </b-form-group>

          <!-- REGIONS -->
          <b-form-group
            :label="$t('tournament.region')"
            :label-cols-sm="labelColsSm"
            class="no-delimiter mb-1 pb-1"
          >
            <multiselect
              v-model="filters.regions"
              :options="settings.regions || []"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              label="name"
              track-by="id"
              placeholder=""
            />
          </b-form-group>
        </b-col>
        <b-col sm="0" md="2"></b-col>
      </b-row>
      <b-row>
        <b-col sm="0" md="2"></b-col>
        <b-col class="text-right">
          <b-link @click="resetFilters" class="small">
            {{ $t("tournaments.reset_filters") }}
          </b-link>
        </b-col>
        <b-col sm="0" md="2"></b-col>
      </b-row>
    </b-collapse>

    <b-overlay :show="tournamentsLoading" rounded="sm">
      <b-table-simple responsive class="mb-4">
        <template v-for="(tournaments, monthDate) in groupedTournaments">
          <b-thead :key="monthDate + '_head'" class="mt-5">
            <b-tr>
              <b-th colspan="7" class="monthHeader">
                <h4>
                  {{ printMonthYear(monthDate) }}
                </h4>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody :key="monthDate + '_body'">
            <b-tr>
              <b-th class="status_column">{{ $t("tournaments.status") }}</b-th>
              <b-th class="date_column">{{ $t("tournaments.date") }}</b-th>
              <b-th class="tournament_name_column">{{
                $t("tournaments.tournament_name")
              }}</b-th>
              <b-th class="tournament_type_column">{{
                $t("tournaments.tournament_type")
              }}</b-th>
              <b-th class="club_column">{{ $t("tournaments.club") }}</b-th>
              <b-th class="location_column">{{
                $t("tournaments.location")
              }}</b-th>
              <b-th class="registration_status_column">{{
                $t("tournaments.registration_status")
              }}</b-th>
            </b-tr>
            <b-tr
              v-for="item in tournaments || []"
              :key="item.id"
              :style="getRowStyle(item)"
            >
              <b-td class="status_column">
                <h4>
                  <b-badge v-if="item.status == 'Cancelled'">
                    {{ $t("tournament.status_cancelled") }}
                  </b-badge>

                  <b-badge
                    v-else-if="item.status == 'Approved'"
                    variant="success"
                  >
                    {{ $t("tournament.status_official") }}
                  </b-badge>

                  <b-badge
                    v-else-if="item.status == 'Declined'"
                    variant="primary"
                  >
                    {{ $t("tournament.status_unofficial") }}
                  </b-badge>

                  <b-badge v-else variant="warning">
                    {{ $t("tournament.status_pending") }}
                  </b-badge>

                  <div class="d-flex">
                    <img
                      v-if="item.recognizedByWorldParaAthletics"
                      v-b-popover.hover.top="
                        $t('tournament.world_para_athletics_description')
                      "
                      src="https://foysautournamentprod.blob.core.windows.net/public/World_Para_Athletics_logo.png"
                      class="tournament-list-picture-size mt-2 ml-2"
                    />

                    <img
                      v-if="item.recognizedByWorldAthletics"
                      v-b-popover.hover.top="
                        $t('tournament.world_athletics_description')
                      "
                      src="https://foysautournamentprod.blob.core.windows.net/public/22WA_Rankings_Competition_Logo_RGB.png"
                      class="tournament-list-picture-size mt-2 ml-2"
                    />
                  </div>
                </h4>
              </b-td>
              <b-td class="date_column">
                {{ formatDate(item.startDate, "dd-MM-yyyy") }}
              </b-td>
              <b-td class="tournament_name_column">
                <b-link :to="`?id=${item.id}`">
                  {{ item.name }}
                </b-link>
              </b-td>
              <b-td class="tournament_type_column">
                {{ item.tournamentTypeName }}
              </b-td>
              <b-td class="club_column">
                {{ item.organisationName }}
              </b-td>
              <b-td class="location_column">
                {{ item.address ? item.address.city : "" }}
              </b-td>
              <b-td class="registration_status_column">
                <div class="text-success" v-if="isRegistrationOpenend(item)">
                  {{ $t("tournaments.registration_opened") }}
                </div>
                <div v-else>
                  {{ $t("tournaments.registration_closed") }}
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </template>
      </b-table-simple>

      <b-row
        v-if="tournaments && tournaments.totalCount > tableOptions.perPage"
      >
        <b-col cols="auto" class="mx-2 text-left ml-auto">
          <b-pagination
            v-model="tableOptions.currentPage"
            :total-rows="tournaments.totalCount"
            :per-page="tableOptions.perPage"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import { tournamentsService, settingsService } from "@/services";
import { setDate, format } from "date-fns";
import { BaseDatePicker } from "@foys-tech/foys-base-components";
import { hashMixin } from "@foys-tech/foys-utils";
import nl from "date-fns/locale/nl";

export default {
  mixins: [tournamentsService, settingsService, hashMixin],
  components: { BaseDatePicker },
  data() {
    return {
      filters: {
        startDateAfter: null,
        startDateBefore: null,
        isIndoorFilter: null,
        categories: [],
        categoryIds: [],
        disciplines: [],
        disciplineIds: [],
        tournamentTypes: [],
        tournamentTypeIds: [],
        statuses: [],
        statusIds: [],
        organisations: [],
        organisationIds: [],
        regions: [],
        regionIds: [],
        isHistoric: false,
      },
      quickSearch: null,
      labelColsSm: "2",
      showCollapse: false,
      tableOptions: {
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  computed: {
    statusesList() {
      return [
        { name: this.$t("tournament.status_pending"), id: "Pending" },
        { name: this.$t("tournament.status_official"), id: "Approved" },
        {
          name: this.$t("tournament.status_unofficial"),
          id: "Declined",
        },
      ];
    },
    groupedTournaments() {
      // Make sure the tournaments are ordered by startdate
      var tournamentItems = this.tournaments.items
        ? this.tournaments.items
        : [];
      tournamentItems = tournamentItems.sort((a, b) => {
        return this.filters.isHistoric
          ? new Date(b.startDate) - new Date(a.startDate)
          : new Date(a.startDate) - new Date(b.startDate);
      });

      // Now group them per month
      tournamentItems = tournamentItems.reduce((a, b) => {
        var dateParsed = setDate(new Date(b.startDate), 1).toISOString();
        (a[dateParsed] = a[dateParsed] || []).push(b);
        return a;
      }, {});

      return tournamentItems;
    },
  },
  methods: {
    async loadData() {
      await this.getSettings();
      await this.fetchTournaments();
    },
    async fetchTournaments() {
      var filters = { ...this.filters };

      filters.categoryIds = filters.categories.map((el) => el.id);
      delete filters.categories;

      filters.disciplineIds = filters.disciplines.map((el) => el.id);
      delete filters.disciplines;

      filters.tournamentTypeIds = filters.tournamentTypes.map((el) => el.id);
      delete filters.tournamentTypes;

      filters.organisationIds = filters.organisations.map((el) => el.id);
      delete filters.organisations;

      filters.regionIds = filters.regions.map((el) => el.id);
      delete filters.regions;

      if (filters.isIndoorFilter) {
        filters.isIndoor = filters.isIndoorFilter.id;
        delete filters.isIndoorFilter;
      }

      filters.statuses = filters.statuses.map((el) => el.id);

      await this.getTournaments({
        params: {
          quickSearch: this.quickSearch,
          skipCount:
            (this.tableOptions.currentPage - 1) * this.tableOptions.perPage,
          maxResultCount: this.tableOptions.perPage,
          ...filters,
          sorting: this.filters.isHistoric ? "startDate desc" : "startDate asc",
        },
      });

      if (this.quickSearch || this.filters || this.showCollapse != undefined) {
        this.saveToHash("filter", {
          quickSearch: this.quickSearch,
          filterSearch: this.filters,
          collapse: this.showCollapse,
        });
      }
    },
    isRegistrationOpenend(tournament) {
      var registrationStartDate = new Date(tournament.registrationStartDate);
      var registrationEndDate = new Date(tournament.registrationEndDate);
      var now = new Date();

      if (registrationStartDate <= now && registrationEndDate >= now) {
        return true;
      } else {
        return false;
      }
    },
    printMonthYear(monthDate) {
      return format(new Date(monthDate), "MMMM yyyy", { locale: nl });
    },
    resetFilters() {
      this.filters.startDateAfter = null;
      this.filters.startDateBefore = null;
      this.filters.categories = [];
      this.filters.categoryIds = [];
      this.filters.disciplines = [];
      this.filters.disciplineIds = [];
      this.filters.tournamentTypes = [];
      this.filters.tournamentTypeIds = [];
      this.filters.statuses = [];
      this.filters.statusIds = [];
      this.filters.organisations = [];
      this.filters.organisationIds = [];
    },
    getRowStyle(tournament) {
      if (tournament.tournamentTypeHighlightColor) {
        return `background-color: ${tournament.tournamentTypeHighlightColor}`;
      }

      return "";
    },
  },
  created() {
    const dataFromHash = this.getFromHash();

    if (dataFromHash && dataFromHash.filter) {
      const { quickSearch, filterSearch, collapse } = dataFromHash.filter;
      this.quickSearch = quickSearch;
      this.filters = filterSearch;
      this.showCollapse = collapse;
    }
    this.loadData();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.fetchTournaments();
      },
    },
    tableOptions: {
      deep: true,
      handler() {
        this.fetchTournaments();
      },
    },
  },
};
</script>

<style scoped>
thead:before {
  /* This doesn't work because of border-collapse */
  line-height: 2em;
  content: "_";
  color: white;
  display: block;
}
.monthHeader {
  background-color: #e35300;
}
.monthHeader h4 {
  color: #fff;
  margin-bottom: 0;
}
.status_column {
  width: 100px;
}
.date_column {
  width: 200px;
}
.tournament_name_column {
  width: 300px;
}
.tournament_type_column {
  width: 120px;
}
.club_column {
  width: 280px;
}
.location_column {
  width: 200px;
}
.registration_status_column {
  width: 200px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
