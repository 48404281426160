<template>
  <b-container class="au-tournament">
    <router-view :key="$route.fullPath" />
  </b-container>
</template>

<script>
import axios from "axios";
import { appSettings } from "@/appSettings";

export default {
  async created() {
    axios.defaults.headers["X-FederationId"] = appSettings.FederationId;
  },
};
</script>
FederationId
<style>
.card-header {
  border: none;
  background-color: transparent;
}

.card-header,
.card-body {
  padding: 1rem;
}

.b-calendar .b-calendar-nav {
  display: block !important;
}

.b-calendar .b-calendar-nav .btn {
  padding-left: 2.5rem !important;
}

.btn,
.form-control,
.input-group > .form-control {
  font-size: 1.5rem !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active {
  background-color: #e35300 !important;
  border: solid 1px #e35300 !important;
  background-image: none !important;
}

.btn-outline-secondary {
  border: solid 1px #e35300 !important;
}

hr.orange {
  border-top: 1px solid #e35300;
}

.tournament-picture-size {
  max-width: 160px;
  max-height: 120px;
}

.tournament-list-picture-size {
  max-width: 40px;
  max-height: 30px;
}

.collapse {
  visibility: visible;
}

legend {
  border-bottom: 0 !important;
}
.multiselect__select {
  height: 30px !important;
}
.multiselect__tags {
  min-height: 30px !important;
  border-radius: 2px !important;
  border: 1px solid #ced4da !important;
  padding: 0px 40px 0 8px !important;
  font-size: 12px !important;
}
.multiselect__tag {
  margin-bottom: 1px !important;
}
.multiselect__placeholder {
  margin-bottom: 0px !important;
}
.form-control {
  box-shadow: none !important;
}
</style>