<template>
  <b-card no-body>
    <b-card-body>
      <h3>{{ $t("tournament.categories_and_disciplines") }}</h3>
    </b-card-body>

    <b-table
      :fields="[
        {
          label: $t('tournament.category'),
          key: 'categories',
          thStyle: 'width: 40%',
        },
        { label: $t('tournament.disciplines'), key: 'disciplines' },
      ]"
      :items="sortedItems"
      show-empty
      :empty-text="$t('common.no_records')"
    >
      <template v-slot:cell(categories)="{ item }">
        <span v-for="category in item.categories" :key="category.id"
          >{{ category.name }}<br />
        </span>
      </template>
      <template v-slot:cell(disciplines)="{ item }">
        <div
          v-for="(disciplineType, idx) in Object.values(
            item.disciplinesByType
          ).sort((a, b) => a.disciplineTypePosition - b.disciplineTypePosition)"
          :key="idx"
        >
          <span class="discipline-type font-weight-bold">{{
            disciplineType.disciplineTypeName + ": "
          }}</span>
          {{
            disciplineType.disciplines.map((el) => el.disciplineName).join(", ")
          }}
        </div>

        <div v-for="(allRound, idx) in item.allRounds" :key="`allRound_${idx}`">
          <span
            class="discipline-type font-weight-bold"
            @click="allRound.expand = !allRound.expand"
            >{{ allRound.allRoundName }}
            <b-icon :icon="allRound.expand ? 'chevron-up' : 'chevron-down'"></b-icon
          ></span>
          <b-collapse v-model="allRound.expand">
            {{ allRound.allRoundDisciplines.join(", ") }}
          </b-collapse>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";


export default {
  props: {
    disciplineCategories: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sortedItems() {
      var sortedGroups = [...this.categoryGroups];
      sortedGroups = sortedGroups.sort(
        (a, b) =>
          a.categoryGroupPosition - b.categoryGroupPosition ||
          a.categoryPosition - b.categoryPosition
      );

      sortedGroups.forEach((el) => {
        el.categories = el.categories.sort((a, b) => a.position - b.position);

        el.disciplinesByType = el.disciplines.reduce((grouped, currentItem) => {
          var key = currentItem.disciplineTypeName;

          grouped[key] = grouped[key] || {
            disciplineTypeName: key,
            disciplineTypePosition: currentItem.disciplineTypePosition,
            disciplines: [],
          };

          grouped[key].disciplines.push({
            disciplineName: currentItem.disciplineName,
            disciplinePosition: currentItem.disciplinePosition,
          });
          return grouped;
        }, {});
      });

      return sortedGroups;
    },
    categoryGroups() {
      var groupedByCategoryId = this.disciplineCategories.reduce(
        (grouped, currentItem) => {
          var key = currentItem.categoryId;

          grouped[key] = grouped[key] || {
            categoryId: key,
            categoryName: currentItem.categoryName,
            categoryGroupPosition: currentItem.categoryGroupPosition,
            categoryPosition: currentItem.categoryPosition,
            items: [],
          };

          grouped[key].items.push({
            ...currentItem,
          });
          return grouped;
        },
        {}
      );

      var list = Object.keys(groupedByCategoryId)
        .map((key) => groupedByCategoryId[key])
        .reduce((grouped, currentGroup) => {
          var disciplinesOfCurrentGroup = currentGroup.items
            .filter((x) => x.disciplineId)
            .map((x) => {
              return {
                disciplineName: x.disciplineName,
                disciplinePosition: x.disciplinePosition,
                disciplineTypeName: x.disciplineTypeName,
                disciplineTypePosition: x.disciplineTypePosition,
                disciplineId: x.disciplineId,
              };
            });

          var allRoundsOfCurrentGroup = currentGroup.items
            .filter((x) => x.allRoundId)
            .map((x) => {
              return {
                allRoundName: x.allRoundName,
                allRoundDisciplines: x.allRoundDisciplines,
                allRoundId: x.allRoundId,
              };
            });

          var disciplineIds = disciplinesOfCurrentGroup.map(
            (el) => el.disciplineId
          );
          var allRoundIds = allRoundsOfCurrentGroup.map((el) => el.allRoundId);

          var groupWithTheSameDisciplinesAndAllRounds = grouped.find((el) => {
            return (
              _.isEmpty(
                _.xor(
                  disciplineIds,
                  el.disciplines.map((x) => x.disciplineId)
                )
              ) &&
              _.isEmpty(
                _.xor(
                  allRoundIds,
                  el.allRounds.map((x) => x.allRoundId)
                )
              )
            );
          });

          if (groupWithTheSameDisciplinesAndAllRounds) {
            groupWithTheSameDisciplinesAndAllRounds.categories.push({
              name: currentGroup.categoryName,
              position: currentGroup.categoryPosition,
            });
          } else {
            grouped.push({
              categories: [
                {
                  id: uuidv4(),
                  name: currentGroup.categoryName,
                  position: currentGroup.categoryPosition,
                },
              ],
              categoryGroupPosition: currentGroup.categoryGroupPosition,
              categoryPosition: currentGroup.categoryPosition,
              disciplines: disciplinesOfCurrentGroup,
              allRounds: allRoundsOfCurrentGroup,
            });
          }

          return grouped;
        }, []);

      return list;
    },
  },
};
</script>
