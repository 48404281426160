import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCalendarAlt,
  faArrowToRight,
  faChevronRight,
  faChevronDoubleRight,
  faArrowToLeft,
  faChevronLeft,
  faChevronDoubleLeft,
  faQuestionCircle
} from '@fortawesome/pro-light-svg-icons';

import {
  faCircle as fasCircle,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faCalendarAlt,
  faArrowToRight,
  faChevronRight,
  faChevronDoubleRight,
  faArrowToLeft,
  faChevronLeft,
  faChevronDoubleLeft,
  faQuestionCircle,
  fasCircle
);