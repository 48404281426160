import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const countryLocales = require.context(
    "./locales/countries",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {}
  locales.keys().filter((p) => p.indexOf("./countries") < 0)
    .forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
        if (countryLocales.keys().indexOf(key) >= 0) {
          messages[locale]["countries"] = countryLocales(key);
        }
      }
    })
  return messages
}

function loadLocaleFromMeta() {
  let metaElem = document.querySelectorAll('[name*=locale]')[0];
  if (typeof metaElem === 'undefined') {
    return "nl";
  }
  let locale = metaElem.getAttribute('Content');
  if (!locale) {
    return "nl";
  }
  return locale;
}

export function getShortLocaleCode() {
  let locale = loadLocaleFromMeta();
  return locale.substring(0, 2);
}

const numberFormats = {
  "en": {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    percent: {
      style: "percent",
    },
  },
  "nl": {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    percent: {
      style: "percent",
    },
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
  numberFormats
})

