import { mapGetters, mapActions } from 'vuex';

export const settingsService = {
  computed: {
    ...mapGetters([
      'settings', 'settingsLoading'
    ])
  },
  methods: {
    ...mapActions([
      'getSettings'
    ])
  }
}