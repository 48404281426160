import { mapGetters, mapActions } from 'vuex';

export const tournamentsService = {
    computed: {
        ...mapGetters([
            'tournaments', 'tournamentsLoading',
            'tournament', 'tournamentLoading'
        ])
    },
    methods: {
        ...mapActions([
            'getTournaments',
            'getTournament'
        ])
    }
}