import './plugins/fontAwesome';
import "./plugins/public-path";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import { getShortLocaleCode } from "./i18n";
import { BootstrapVue, BIcon, BIconChevronUp, BIconChevronDown, BIconCircleFill } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { dateTimeService } from "@foys-tech/foys-utils/dateTimeService";

import moment from "moment";
Vue.prototype.$moment = moment;


import { ValidationObserver, ValidationProvider, localize } from 'vee-validate';
import en from "vee-validate/dist/locale/en.json";
import nl from "vee-validate/dist/locale/nl.json";

Vue.use(BootstrapVue);
Vue.component('BIcon', BIcon)
Vue.component('BIconChevronUp', BIconChevronUp)
Vue.component('BIconChevronDown', BIconChevronDown)
Vue.component('BIconCircleFill', BIconCircleFill)

Vue.mixin(dateTimeService);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

localize({ nl, en });

let locale = getShortLocaleCode();
localize(locale);

Vue.filter("yesNo", function (value) {
  return value ? i18n.t("common.yes") : i18n.t("common.no");
});

const displayShortDateFormat = 'DD-MM-YYYY';
Vue.filter("shortDate", function shortDateFilter(value) {
  let defaultValue = '-';
  if (value == null) {
    return defaultValue
  };
  let date = moment.utc(value);
  return date.isValid() ? date.format(displayShortDateFormat) : defaultValue;

})

import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);

Vue.prototype.$eventHub = new Vue();
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
