import Vue from "vue";
import Vuex from "vuex";
import { appSettings } from "@/appSettings";

import tournaments from "./tournaments";
import settings from "./settings";
import accommodations from "./accommodations";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [],
  state: {
    FederationId: appSettings.FederationId
  },
  mutations: {},
  actions: {},
  modules: {
    tournaments,
    settings,
    accommodations
  }
});
