export const registrationSystemsEnum = {
    data() {
        return {
            registrationSystems: {
                Seltec: "Seltec",
                AtleticNU: "AtleticNU",
            },
        }
    }
}

export const pageNamesEnum = {
    data() {
        return {
            pageNames: {
                Details: "details",
                Results: "results",
                TimeSchedule: "timeschedule",
                Registrations: "registrations",
                Participants: "participants"
            },
        }
    }
}