import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const settings = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "settings"
})
  .get({
    path: `settings`,
    queryParams: true,
  }, 'settings', [])
  .build();

export default settings;
