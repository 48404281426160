<template>
  <b-row class="my-4">
    <b-col cols="12" md="5">
      <b-card class="mb-4">
        <h3>{{ $t("tournament.match_details") }}</h3>

        <b-row>
          <b-col class="font-weight-bold" md="5"
            >{{ $t("tournament.organisation") }}:</b-col
          >
          <b-col>{{ tournament.organisationName }}</b-col>
        </b-row>

        <b-row v-if="tournament.address">
          <b-col class="font-weight-bold" md="5"
            >{{ $t("tournament.address") }}:</b-col
          >
          <b-col>
            {{ tournament.address.address1 }}
            {{ tournament.address.houseNumber }}
            {{
              tournament.address.houseNumberExtension
                ? tournament.address.houseNumberExtension
                : ""
            }}<br />
            {{ tournament.address.zipCode }} {{ tournament.address.city }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold" md="5"
            >{{ $t("tournament.region") }}:</b-col
          >
          <b-col>{{ tournament.regionName }}</b-col>
        </b-row>

        <b-row
          v-if="
            tournament.registrationStartDate || tournament.registrationEndDate
          "
        >
          <b-col class="font-weight-bold" md="5"
            >{{ $t("tournament.registration_period") }}:</b-col
          >
          <b-col
            >{{ formatDate(tournament.registrationStartDate, "dd-MM-yyyy") }}
            {{ $t("tournament.until") }}
            {{
              formatDate(tournament.registrationEndDate, "dd-MM-yyyy")
            }}</b-col
          >
        </b-row>

        <b-row v-if="tournament.emailAddress">
          <b-col class="font-weight-bold" md="5"
            >{{ $t("tournament.email") }}:</b-col
          >
          <b-col>{{ tournament.emailAddress }}</b-col>
        </b-row>

        <b-row v-if="tournament.phoneNumber">
          <b-col class="font-weight-bold" md="5"
            >{{ $t("tournament.phone_number") }}:</b-col
          >
          <b-col>{{ tournament.phoneNumber }}</b-col>
        </b-row>

        <b-row v-if="tournament.website">
          <b-col class="font-weight-bold" md="5"
            >{{ $t("tournament.website") }}:</b-col
          >
          <b-col
            ><a :href="websiteUrl" target="_blank">{{
              tournament.website
            }}</a></b-col
          >
        </b-row>

        <b-row>
          <b-col class="font-weight-bold" md="5"
            >{{ $t("tournament.outdoor_or_indoor") }}:</b-col
          >
          <b-col>{{
            tournament.isIndoor
              ? $t("tournament.indoor")
              : $t("tournament.outdoor")
          }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold" md="5"
            >{{ $t("tournament.time_measurement") }}:</b-col
          >
          <b-col>{{
            tournament.hasElectronicDistanceMeasurement | yesNo
          }}</b-col>
        </b-row>

        <b-row
          v-if="
            tournament.registrationOpenForClubs ||
            tournament.registrationOpenForAllClubs
          "
        >
          <b-col class="font-weight-bold" md="5"
            >{{ $t("tournament.registration_open_for") }}:</b-col
          >
          <b-col>{{
            tournament.registrationOpenForAllClubs
              ? $t("common.all_clubs")
              : tournament.registrationOpenForClubs.join(", ")
          }}</b-col>
        </b-row>
      </b-card>

      <tournament-disciplines-card v-if="!tournamentLoading" />
    </b-col>

    <b-col cols="12" md="7">
      <b-card class="mb-4">
        <h3>{{ $t("tournament.description") }}</h3>
        <div style="white-space: pre-wrap">
          {{ tournament.description }}
        </div>
      </b-card>

      <discipline-category-simple-view
        v-if="!tournamentLoading"
        :accommodation-id="tournament.accommodationId"
        :discipline-categories="tournament.disciplineCategories"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  DisciplineCategorySimpleView,
  TournamentDisciplinesCard,
} from "@/app/Tournaments";
import { tournamentsService } from "@/services";

export default {
  mixins: [tournamentsService],
  components: { DisciplineCategorySimpleView, TournamentDisciplinesCard },
  computed: {
    websiteUrl() {
      var url = this.tournament.website;
      if (!url.startsWith("http")) {
        url = "https://" + url;
      }
      return url;
    },
    locationFields() {
      return [
        {
          label: this.$t("common.location"),
          key: "name",
        },
        {
          label: this.$t("common.type"),
          key: "locationType",
        },
      ];
    },
    meetingFields() {
      return [
        {
          label: this.$t("common.type"),
          key: "meetingTypeName",
        },
        {
          label: this.$t("common.location"),
          key: "location",
        },
        {
          label: this.$t("common.date"),
          key: "date",
        },
        {
          label: this.$t("common.start_time"),
          key: "startTime",
        },
        {
          label: this.$t("common.end_time"),
          key: "endTime",
        },
      ];
    },
    contactPersonFields() {
      return [
        {
          label: "",
          key: "roleName",
          tdClass: "pl-0",
        },
        {
          label: "",
          key: "name",
        },
      ];
    },
  },
};
</script>
