<template>
  <tournament-details-page v-if="$route.query?.id" />
  <tournaments-overview-page v-else />
</template>

<script>
import {
  TournamentsOverviewPage,
  TournamentDetailsPage,
} from "@/app/Tournaments";

export default {
  components: { TournamentsOverviewPage, TournamentDetailsPage },
};
</script>

<style scoped>
@import 'https://unpkg.com/bootstrap@4.6.1/dist/css/bootstrap.min.css';
</style>
