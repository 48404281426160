import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const accommodations = new BaseStoreBuilder({
  baseURL: appSettings.AccommodationBaseUrl,
  entityName: "accommodations"
})
  .get({
    path: ({ accommodationId }) => `accommodations/${accommodationId}/inspection-summary`,
    queryParams: true,
  }, 'accommodationInspectionSummary', [])
  .build();

export default accommodations;
