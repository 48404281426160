<template>
  <b-card>
    <h3>{{ $t("tournament.disciplines") }}</h3>

    <b-overlay
      v-if="tournament.accommodationId"
      :show="accommodationInspectionSummaryLoading"
      class="mb-3 mt-3"
    >
      <b-row>
        <b-col class="font-weight-bold" md="5"
          >{{ $t("inspection_summary.inspection_valid_until") }}:</b-col
        >
        <b-col>{{
          accommodationInspectionSummary.expiryDateOfInspection | shortDate
        }}</b-col>
      </b-row>

      <b-row>
        <b-col class="font-weight-bold" md="5"
          >{{ $t("inspection_summary.status") }}:</b-col
        >
        <b-col>
          <b-icon
            icon="circle-fill"
            :class="
              isAccommodationInspectionExpired ? 'notValidated' : 'validated'
            "
        /></b-col>
      </b-row>
    </b-overlay>

    <b-table
      :small="true"
      :fixed="true"
      class="table-borderless"
      :fields="fields"
      :items="tournament.disciplines"
      show-empty
    >
      <template v-slot:empty="">{{ $t("common.no_records") }}</template>
      <template v-slot:cell(name)="{ item }">
        {{ item.disciplineName || item.allRoundName }}
      </template>
      <template v-slot:cell(matchFit)="{ item }">
        <span v-if="tournament.accommodationId && item.facilityTypeCode">
          <b-icon
            icon="circle-fill"
            :class="`${
              isFacilityValidated(item.facilityTypeCode)
                ? 'validated'
                : 'notValidated'
            } ml-2`"
          />
        </span>
      </template>
    </b-table>
    <b-row class="inspection-btns">
      <b-col>
        <b-button
          variant="outline-secondary"
          :href="indoorCheckUrl"
          target="blank"
          >{{ $t("tournament.indoor") }}</b-button
        >

        <b-button
          variant="outline-secondary"
          class="ml-2"
          :href="outdoorCheckUrl"
          target="blank"
          >{{ $t("tournament.outdoor") }}
        </b-button></b-col
      >
    </b-row>
  </b-card>
</template>

<script>
import { tournamentsService, accommodationsService } from "@/services";
import { isValid, parseJSON } from "date-fns";

export default {
  mixins: [tournamentsService, accommodationsService],
  async created() {
    if (this.tournament.accommodationId) {
      await this.getAccommodationInspectionSummary({
        params: {
          accommodationId: this.tournament.accommodationId,
        },
      });
    }
  },
  methods: {
    isFacilityValidated(facilityTypeCode) {
      if (!facilityTypeCode) {
        return false;
      }

      return (this.accommodationInspectionSummary.facilities || []).find(
        (x) => x.key === facilityTypeCode && x.value
      );
    },
  },
  computed: {
    isAccommodationInspectionExpired() {
      let dateObj = parseJSON(
        this.accommodationInspectionSummary.expiryDateOfInspection
      );
      if (!isValid(dateObj)) return null;

      return new Date().getTime() >= dateObj.getTime();
    },
    fields() {
      return [
        { label: this.$t("common.name"), key: "name" },
        { label: this.$t("inspection_summary.match_fit"), key: "matchFit" },
      ];
    },
    indoorCheckUrl() {
      return "https://www.atletiekunie.nl/kenniscentrum/accommodatiezaken/inspectie/accommodaties-en-inspectietermijnen-indoor/";
    },
    outdoorCheckUrl() {
      return "https://www.atletiekunie.nl/kenniscentrum/accommodatiezaken/inspectie/accommodaties-en-inspectietermijnen-outdoor/";
    },
  },
};
</script>

<style scoped>
.validated {
  color: #27c24c;
}

.notValidated {
  color: #ff0000;
}
</style>
