var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('h3',[_vm._v(_vm._s(_vm.$t("tournament.categories_and_disciplines")))])]),_c('b-table',{attrs:{"fields":[
        {
          label: _vm.$t('tournament.category'),
          key: 'categories',
          thStyle: 'width: 40%',
        },
        { label: _vm.$t('tournament.disciplines'), key: 'disciplines' },
      ],"items":_vm.sortedItems,"show-empty":"","empty-text":_vm.$t('common.no_records')},scopedSlots:_vm._u([{key:"cell(categories)",fn:function({ item }){return _vm._l((item.categories),function(category){return _c('span',{key:category.id},[_vm._v(_vm._s(category.name)),_c('br')])})}},{key:"cell(disciplines)",fn:function({ item }){return [_vm._l((Object.values(
            item.disciplinesByType
          ).sort((a, b) => a.disciplineTypePosition - b.disciplineTypePosition)),function(disciplineType,idx){return _c('div',{key:idx},[_c('span',{staticClass:"discipline-type font-weight-bold"},[_vm._v(_vm._s(disciplineType.disciplineTypeName + ": "))]),_vm._v(" "+_vm._s(disciplineType.disciplines.map((el) => el.disciplineName).join(", "))+" ")])}),_vm._l((item.allRounds),function(allRound,idx){return _c('div',{key:`allRound_${idx}`},[_c('span',{staticClass:"discipline-type font-weight-bold",on:{"click":function($event){allRound.expand = !allRound.expand}}},[_vm._v(_vm._s(allRound.allRoundName)+" "),_c('b-icon',{attrs:{"icon":allRound.expand ? 'chevron-up' : 'chevron-down'}})],1),_c('b-collapse',{model:{value:(allRound.expand),callback:function ($$v) {_vm.$set(allRound, "expand", $$v)},expression:"allRound.expand"}},[_vm._v(" "+_vm._s(allRound.allRoundDisciplines.join(", "))+" ")])],1)})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }